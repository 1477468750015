<template>
  <b-card>
    <validation-observer ref="validateProfile">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Foto Profil"
            >
              <vue-dropzone
                id="dropzone"
                ref="dropAvatar"
                :options="dropzoneOptions"
                :use-custom-slot="true"
                @vdropzone-error="dokumenError;"
                @vdropzone-file-added="removePreviousFile('dropAvatar'); completeUpload($event, 'uploadAvatar')"
                @vdropzone-removed-file="(file, error, xhr) => removeFile('dropAvatar', file, error,xhr)"
              >
                <div class="d-flex align-items-center justify-content-center h-100 pt-50">
                  <h6 class="text-muted font-weight-normal mt-2">
                    Taruh Foto Disini
                  </h6>
                </div>
              </vue-dropzone>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Foto Tanda Tangan"
            >
              <vue-dropzone
                id="dropzone"
                ref="dropTtd"
                :options="dropzoneOptions"
                :use-custom-slot="true"
                @vdropzone-error="dokumenError"
                @vdropzone-file-added="removePreviousFile('dropTtd'); completeUpload($event, 'uploadTtd')"
                @vdropzone-removed-file="(file, error, xhr) => removeFile('dropTtd', file, error,xhr)"
              >
                <div class="d-flex align-items-center justify-content-center h-100 pt-50">
                  <h6 class="text-muted font-weight-normal mt-2">
                    Taruh Foto Disini
                  </h6>
                </div>
              </vue-dropzone>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nama"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama"
                rules="required|min:2|max:25"
              >
                <b-form-input
                  v-model="profileData.nama"
                  placeholder="Nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="profileData.email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nomor Telepon"
            >
              <b-form-input
                v-model="profileData.phone"
                disabled
                placeholder="Nomor Telepon"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-collapse :visible="showSubmitReset">
          <div class="d-flex mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              variant="primary"
              @click="loading ? null : submitForm()"
            >
              <span v-if="!loading">Submit Data Profile</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="resetForm()"
            >
              Reset
            </b-button>
          </div>
        </b-collapse>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCollapse, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, max,
} from '@validations'
import VueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCollapse,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    VueDropzone,
  },
  props: {
    userdata: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
      min,
      max,
      profileData: {
        nama: '',
        email: '',
        phone: '',
      },
      loading: false,
      uploadAvatar: false,
      uploadTtd: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles: '.jpg, .jpeg, .png',
        maxFilesize: 0.5,
        autoProcessQueue: false,
        dictDefaultMessage: 'Taruh Foto Disini',
        uploadMultiple: false,
        maxFiles: 1,
        dictCancelUpload: 'Batalkan',
        dictCancelUploadConfirmation: 'Apakah anda yakin?',
        dictRemoveFile: 'Hapus File',
        dictFileTooBig: 'File terlalu besar ({{filesize}}MB). Maximal ukuran: {{maxFilesize}}MB.',
        dictInvalidFileType: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .jpg, .jpeg, dan .png',
        dictMaxFilesExceeded: 'Tidak boleh mengupload lebih dari 1 file. Hapus file sebelumnya terlebih dahulu.',
      },
    }
  },
  computed: {
    showSubmitReset() {
      if (this.profileData.nama !== this.userdata.name) return true
      if (this.profileData.email !== this.userdata.email) return true
      if (this.profileData.phone !== this.userdata.phone) return true
      if (this.uploadAvatar) return true
      if (this.uploadTtd) return true
      return false
    },
  },
  mounted() {
    this.initForm()
  },
  methods: {
    submitForm() {
      this.$refs.validateProfile.validate().then(success => {
        if (success) {
          this.loading = true
          this.$store.dispatch('updateUser', {
            name: this.profileData.nama,
            email: this.profileData.email,
          }).then(async result => {
            if (result.data.updateUser.status === 'success') {
              if (this.uploadAvatar && this.$refs.dropAvatar.getAcceptedFiles().length) {
                await this.$store.dispatch('updateProfilePicture', this.$refs.dropAvatar.getAcceptedFiles()[0])
              } else if (this.uploadAvatar && !this.$refs.dropAvatar.dropzone.files.length) {
                await this.$store.dispatch('deleteProfilePicture')
              }

              if (this.uploadTtd && (this.$refs.dropTtd.getAcceptedFiles().length || this.$refs.dropTtd.dropzone.files.length)) {
                await this.$store.dispatch('updateTTD', this.$refs.dropTtd.getAcceptedFiles()[0])
              } else if (this.uploadTtd && !this.$refs.dropTtd.dropzone.files.length) {
                await this.$store.dispatch('deleteTTD')
              }
              this.uploadAvatar = false
              this.uploadTtd = false
              this.loading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil mengupdate data profile!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            }
          }).catch(() => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal mengupdate data profile!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Periksa kembali data anda',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    initForm() {
      this.profileData = {
        nama: this.userdata.name,
        email: this.userdata.email,
        phone: this.userdata.phone,
      }

      this.$refs.dropAvatar.dropzone.files.forEach(el => {
        this.$refs.dropAvatar.removeFile(el)
      })

      this.$refs.dropTtd.dropzone.files.forEach(el => {
        this.$refs.dropTtd.removeFile(el)
      })

      if (this.userdata.photo) {
        const imgType = this.userdata.photo.split(/[#?]/)[0].split('.').pop().trim()
        const file = { size: 1024 * 500, name: 'Foto Profil', type: `image/${imgType}` }
        this.$refs.dropAvatar.manuallyAddFile(file, this.userdata.photo)
      }

      if (this.userdata.ttd) {
        const imgType = this.userdata.ttd.split(/[#?]/)[0].split('.').pop().trim()
        const file = { size: 1024 * 500, name: 'Foto TTD', type: `image/${imgType}` }
        this.$refs.dropTtd.manuallyAddFile(file, this.userdata.ttd)
      }

      this.$refs.validateProfile.reset()
    },
    resetForm() {
      this.$swal({
        title: 'Reset form?',
        text: 'Progress pengisian form anda akan direset dan tidak dapat dikembalikan.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.initForm()
        }
      })
    },
    dokumenError(file, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
    },
    completeUpload(data, id) {
      if (!data.manuallyAdded) {
        this[id] = true
      } else {
        this[id] = false
      }
    },
    async removeFile(ref) {
      if (ref === 'dropAvatar') this.uploadAvatar = true
      if (ref === 'dropTtd') this.uploadTtd = true
    },
    removePreviousFile(ref) {
      const photo = ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG']
      if (this.$refs[ref].dropzone.files.length > 1) {
        this.$refs[ref].removeFile(this.$refs[ref].dropzone.files[0])
      }
      const filename = this.$refs[ref].dropzone.files[0]?.upload?.filename.split('.')
      const format = filename?.length ? filename[filename.length - 1] : null
      if (!photo.includes(format) && filename) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .jpg, .jpeg, dan .png',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.$refs[ref].removeFile(this.$refs[ref].dropzone.files[0])
      }
      if (this.$refs[ref].dropzone.files[0]?.upload?.total >= 500000) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `File terlalu besar (${this.$refs[ref].dropzone.files[0]?.upload?.total / 1000000} MB). Maximal ukuran: ${500000 / 1000000} MB.`,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.$refs[ref].removeFile(this.$refs[ref].dropzone.files[0])
      }
    },
  },
}
</script>

<style lang="scss">
  .dz-progress {
    display: none;
  }
  .dropzone {
    padding: 0 !important;

    .dark-layout & {
      background: #283046;
    }
  }
  .vue-dropzone {
    border-radius: 4px;
    border: 1px solid #d8d6de;

    .dark-layout & {
      border: 1px solid #404656;
    }
  }
</style>
