<template>
  <b-card>
    <validation-observer ref="validatePassword">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Password Lama"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Password Lama"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Password Baru"
            >
              <validation-provider
                #default="{ errors }"
                name="Password Baru"
                rules="required|min:6|max:25"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Password Baru"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-row class="mt-50">
                <b-col>
                  <password
                    v-model="newPasswordValue"
                    :strength-meter-only="true"
                    @score="showScore"
                  />
                </b-col>
                <b-col
                  v-if="passwordScore.variant"
                  class="d-flex"
                  cols="auto"
                >
                  <small :class="`ml-auto ${passwordScore.variant}`">{{ passwordScore.message }}</small>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Ulangi Password Baru"
            >
              <validation-provider
                #default="{ errors }"
                name="Konfirmasi Password Baru"
                rules="required|min:6|max:25"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Password Baru"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ passwordConfirmation ? passwordConfirmation : errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-collapse :visible="showSubmit">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click="loading ? null : submitPassword()"
              >
                <span v-if="!loading">Simpan Password</span>
                <b-spinner
                  v-else
                  small
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="mt-1"
                @click="resetForm"
              >
                Reset
              </b-button>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
  BCollapse, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Password from 'vue-password-strength-meter'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BCollapse,
    BSpinner,
    Password,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,
      max,
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      passwordScore: '',
      loading: false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmation() {
      if ((this.newPasswordValue.length > 5 && this.retypePassword.length > 5) && this.newPasswordValue !== this.retypePassword) {
        return 'Konfirmasi password tidak sama dengan password baru'
      }
      return ''
    },
    showSubmit() {
      if (this.passwordValueOld && this.newPasswordValue && this.retypePassword) return true
      return false
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    showScore(score) {
      if (score >= 0 && score < 2) {
        this.passwordScore = {
          variant: 'text-danger',
          message: 'Password Lemah',
        }
      } else if (score === 3) {
        this.passwordScore = {
          variant: 'text-info',
          message: 'Password Cukup Bagus',
        }
      } else if (score > 3) {
        this.passwordScore = {
          variant: 'text-success',
          message: 'Password Sangat Bagus',
        }
      }

      if (score === null) {
        this.passwordScore = {
          variant: '',
          message: '',
        }
      }
    },
    submitPassword() {
      this.$refs.validatePassword.validate().then(success => {
        if (success) {
          this.loading = true
          this.$store.dispatch('changePassword', {
            oldPassword: this.passwordValueOld,
            newPassword: this.retypePassword,
          }).then(() => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil mengubah password!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            setTimeout(() => {
              this.$router.go()
            }, 1000)
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    resetForm() {
      this.$swal({
        title: 'Reset form?',
        text: 'Progress pengisian form anda akan direset dan tidak dapat dikembalikan.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.passwordValueOld = ''
          this.newPasswordValue = ''
          this.retypePassword = ''
          this.passwordScore = {
            variant: '',
            message: '',
          }
          this.$refs.validatePassword.reset()
        }
      })
    },
  },
}
</script>

<style>
.Password {
  max-width: 100% !important;
}
.Password__strength-meter {
  margin-bottom: 8px;
}
</style>
