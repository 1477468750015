<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Pengaturan Profile</span>
      </template>
      <general-setting :userdata="userdata.user" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Ubah Password</span>
      </template>
      <password-setting />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="MailIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Autentikasi Email</span>
      </template>
      <auth-page />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import PasswordSetting from './components/PasswordSetting.vue'
import GeneralSetting from './components/GeneralSetting.vue'
import AuthPage from './components/AuthPage.vue'

export default {
  components: {
    BTabs,
    BTab,
    AuthPage,
    PasswordSetting,
    GeneralSetting,
  },
  computed: {
    ...mapGetters({
      userdata: 'getCurrentUser',
    }),
  },
}
</script>

<style>

</style>
